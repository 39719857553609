<template>
    <content-view>
        <div class="course-finish">
            <div class="course-finish__wrapper">
                <div class="course-finish__icon"></div>
                <h1 class="course-finish__title">Поздравляем, вы успешно прошли тестирование и закончили курс!</h1>
                <ValidationObserver tag="form" class="course-finish__form" @submit.prevent="sendReview" ref="observer" method="post">
                    <ValidationProvider rules="required" vid="review" v-slot="provider">
                        <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                            <label for="id_review" class="course-finish__label">Оставьте отзыв об обучении</label>
                            <textarea maxlength="100" v-model="review" class="course-finish__textarea" name="review" id="id_review"></textarea>
                            <div id="id_review-error" class="invalid-feedback animated fadeInDown"
                                 v-if="provider.errors.length > 0"> {{provider.errors[0]}}
                            </div>
                        </div>
                    </ValidationProvider>
                    <button class="v-btn" type="submit">Отправить</button>
                </ValidationObserver>
            </div>
        </div>
    </content-view>
</template>

<script>
import session from "@/api/session";

export default {
    name: 'CourseFinish',
    components: {
        ContentView : () => import('@/views/menu/ContentView')
    },
    data() {
        return {
            review: '',
            courseId: this.$route.params.course_id
        }
    },
    methods: {
        async sendReview() {
            let isValid = await this.$refs.observer.validate();
            if (isValid) {
                try {
                    let request = session.post(`/api/v1/course/${this.courseId}/review/`, {
                        review: this.review
                    });
                    let response = await request;
                    this.$router.push(`/courses/${this.courseId}`);
                    const ThanksFeedback = () => import("@/components/course/modals/ThanksFeedback");
                    this.$modal.show(
                        ThanksFeedback,
                        {},
                        {
                            name: "thanks-feedback",
                            adaptive: true,
                            maxWidth: 528,
                            width: "100%",
                            height: "auto",
                        }
                    );
                } catch (err) {
                    console.error(err)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";

.course-finish {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 120px 0 80px;

    color: $black;
    background-color: $white;

    @include below($tablet) {
        width: auto;
        padding: 80px 0;
    }

    @include below($mobile) {
        padding: 40px 0;
        margin-bottom: 20px;
    }

    &__wrapper {
        max-width: rem(705);
        text-align: center;
    }

    &__icon {
        margin: 0 auto rem(47);
        width: rem(345);
        height: rem(245);
        background-image: url("~assets/img/course/finish.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        @include below($mobile) {
            width: 80%;
            margin-bottom: rem(30);

        }
    }

    &__title {
        margin-bottom: rem(55);

        font-size: rem(40);
        line-height: 1.3;

        @include below($mobile) {
            margin: 0 auto 20px;

            font-size: rem(22);
        }
    }

    &__label {
        display: block;
        margin-bottom: rem(23);

        font-weight: 600;

        @include below($mobile) {
            margin-bottom: 27px;

            font-size: 16px;
            line-height: 24px;
        }
    }

    &__textarea {
        display: block;

        width: 100%;
        max-width: rem(355);
        min-height: 150px;
        padding: 6px 11px;
        margin: 0 auto 20px;

        font-size: rem(14);
        line-height: 1.57;
        font-weight: 600;

        border-radius: 2px;
        border-color: #D5DBE4;
        resize: vertical;
        outline: 0;
        transition: border-color 0.3s;

        @include below($mobile) {
            width: 95%;

            font-size: 14px;
        }

        &:hover,
        &:focus {
            border-color: #959595;
        }
    }
}
</style>
